import { useState } from 'react'
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { ethers } from 'ethers';
import { Grommet, grommet } from 'grommet';
import { Toaster } from 'react-hot-toast';

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'
import { MetaMaskContext, useConnectMetamask } from './eth/ethers'

import './index.scss';
import { useEffectOnce } from 'react-use';
import MetaMaskSetter from './components/MetaMaskSetter';

const theme = {
  global: {
    font: {
      family: 'system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,sans-serif',
    },
    colors: {
      // brand: '#006d77',
    },
  },
};

const App = () => {
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);

  const metamaskProviderValue = {
    provider,
    setProvider,
  };

  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <MetaMaskContext.Provider value={metamaskProviderValue}>
          <MetaMaskSetter/>
          <Grommet theme={theme} style={{
            height: '100%',
          }}>
            <RedwoodApolloProvider>
              <Toaster/>
              <Routes />
            </RedwoodApolloProvider>
          </Grommet>
        </MetaMaskContext.Provider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  );
}

export default App
