import { createContext, useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { useEffectOnce } from "react-use";
import Compound from '@compound-finance/compound-js';


export const MetaMaskContext = createContext({
  provider: null,
  setProvider: (provider: ethers.providers.Web3Provider) => {},
});

export const useConnectMetamask = () => {
  const metaMask = useContext(MetaMaskContext);

  return async () => {
    if (!metaMask.provider) {
      const provider = new ethers.providers.Web3Provider((window as any).ethereum);
      await provider.send("eth_requestAccounts", []);

      metaMask.setProvider(provider);
    }
  };
};

export const useMetamask = (): ethers.providers.Web3Provider | null => {
  const metaMask = useContext(MetaMaskContext);

  return metaMask.provider;
};

export const useSigner = (): ethers.Signer | null => {
  const provider = useMetamask();
  const [signer, setSigner] = useState<ethers.Signer | null>(null);

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setSigner(signer);
    }
  }, [provider]);

  return signer;
};

export const useCompound = () => {
  const [compound, setCompound] = useState<typeof Compound | null>(null);

  useEffectOnce(() => {
    // @ts-ignore
    const client = new Compound((window as any).ethereum);
    setCompound(client);
  });

  return compound;
};
