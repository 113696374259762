import {useEffectOnce} from "react-use";
import { useConnectMetamask } from "src/eth/ethers";


const MetaMaskSetter = () => {
  const connectMetamask = useConnectMetamask();
  useEffectOnce(() => {connectMetamask()});

  return null;
};

export default MetaMaskSetter;
