import { navigate, routes } from "@redwoodjs/router";
import {
  Header,
  Heading,
  Box,
  Button,
  Notification,
  TextInput,
  Footer,
  Text,
} from "grommet";
import { FormSearch } from "grommet-icons";
import { useState } from "react";
import { ethers } from "ethers";
import toast from "react-hot-toast";

type MainLayoutProps = {
  children?: React.ReactNode
}

const AppBar = (props) => (
   <Box
     tag='header'
     direction='row'
     align='center'
     justify='between'
     background='brand'
     pad={{ left: 'medium', right: 'small', vertical: 'small' }}
     elevation='medium'
     style={{ zIndex: '1' }}
     flex='grow'
     {...props}
   />
);

const AppFooter = () => {
  return (
    <Footer
      background="brand"
      pad="medium"
      flex="shrink"
      direction="row"
      justify="start"
    >
      <Box>
        <Text>Copyright © 2021 Hlaford, LLC. All Rights Reserved</Text>
      </Box>
    </Footer>
  );
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const [contractAddress, setContractAddress] = useState<string>('');

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const isAddress = ethers.utils.isAddress(contractAddress);
      if (isAddress) {
        navigate(routes.contract({ address: contractAddress }));
        setContractAddress('');
      } else {
        toast.error('Invalid address');
      }
    }
  };

  return (
    <Box
      justify="between"
      direction="column"
      height="100%"
    >
      <AppBar>
        <Heading 
          level="3"
          margin="none"
          onClick={() => navigate(routes.home())}
          style={{
            cursor: 'pointer',
          }}
        >
          🍞Hlaford
        </Heading>

        <Box>
          <TextInput 
            placeholder="Contract Address"
            value={contractAddress}
            onChange={(event) => setContractAddress(event.target.value)}
            onKeyDown={(event) => onKeyDown(event)}
            icon={<FormSearch/>}
          />
        </Box>
      </AppBar>

      <Box 
        direction="row"
        flex="grow" 
        overflow={{ horizontal: 'hidden' }} 
        pad="small" 
        style={{
          minHeight: '100%',
        }}
      >
        <Box 
          flex="grow" 
          align='left' 
          justify='start'
        >
          {children}
        </Box>
      </Box>

      <AppFooter />
    </Box>
  );
}

export default MainLayout
